import "core-js/modules/es.array.push.js";
import { mapActions, mapGetters } from "vuex";
import { profileReadonly, registrationType } from "@/helpers/profile";
import { setValueTimeout } from "@/helpers/timeout";
export default {
  name: "TwentyNinth",
  data: () => ({
    home: {},
    registry: {},
    profileObjSaved: {},
    endpoint: "addresses",
    states: {
      index_state: null,
      address_state: null
    }
  }),
  async created() {
    if (!Array.isArray(this.profileObj.addresses) || this.profileObj.addresses.length === 0) {
      // если адресов нет, то создадим 2 шт сразу
      await this.addAddress({
        is_registration: true
      });
      await this.addAddress({
        is_actual: true
      });
    }
    this.home = {
      ...this.profileObj.addresses.find(el => el.is_actual),
      ...this.states
    };
    this.profileObjSaved[this.home.id] = {
      ...this.home
    };
    this.registry = {
      ...this.profileObj.addresses.find(el => el.is_registration),
      ...this.states,
      registration_type_state: null
    };
    this.profileObjSaved[this.registry.id] = {
      ...this.registry
    };
  },
  methods: {
    ...mapActions("profile", ["addAddressRecord", "updateProfileData"]),
    async addAddress(fields) {
      await this.addAddressRecord({
        profileId: this.profileObj.id,
        fields
      }).then(response => {
        this.profileObj.addresses[response.id] = response;
      });
    },
    async submitDataEndpoint(obj, key) {
      if (this.profileObjSaved[obj.id][key] === obj[key]) return;
      const state = `${key}_state`;
      obj[state] = false;
      await this.updateProfileData({
        id: this.profileObj.id,
        dataId: obj.id,
        params: {
          [key]: obj[key]
        },
        endpoint: this.endpoint
      }).then(async response => {
        this.profileObjSaved[response.id] = response;
        await setValueTimeout(obj, state);
      });
    }
  },
  computed: {
    ...mapGetters("profile", ["profileObj"]),
    registrationTypeOption() {
      return Object.keys(registrationType).reduce((acc, curr) => {
        acc.push({
          value: curr,
          text: registrationType[curr]
        });
        return acc;
      }, []);
    },
    readonly() {
      return profileReadonly(this.profileObj.state);
    },
    homeMatchRegistry: {
      get() {
        return ["index", "address"].every(key => this.home[key] === this.registry[key]);
      },
      async set(value) {
        if (value) {
          this.registry.index = this.home.index;
          this.registry.address = this.home.address;
          await this.submitDataEndpoint(this.registry, "index");
          await this.submitDataEndpoint(this.registry, "address");
        }
      }
    }
  }
};