import { dispatchSuccess, dispatchError } from "@/helpers/text";
import store from "@/store";
import links from "@/router/links";

export const ff = Object.keys(process.env).reduce((acc, key) => {
  if (String(key).slice(0, 3) === "FF_") {
    acc[String(key).slice(3)] = process.env[key] === "true";
  }
  return acc;
}, {});

export async function wrapCommitDispatch(context, fun, wrap) {
  if (wrap !== undefined) context.commit(wrap, true);
  try {
    const msg = await fun();
    if (msg !== undefined) dispatchSuccess(context.dispatch, msg);
  } catch (error) {
    dispatchError(context.dispatch, error);
  } finally {
    if (wrap !== undefined) context.commit(wrap, false);
  }
}

// сравнение 2х объектов на равенство
export function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
}

function isObject(object) {
  return object !== null && typeof object === "object";
}

// сравнение двух массивов на равенство
// order=false независимо от порядка, т.е. [1, 2] = [2, 1]
// order=true порядок важен
export function arrayEqual(arr1, arr2, order = false) {
  if (
    !Array.isArray(arr1) ||
    !Array.isArray(arr2) ||
    arr1.length !== arr2.length
  )
    return false;
  return order
    ? arr1.every((el, index) => el === arr2[index])
    : arr1.every(
        (el, index) => arr2.includes(el) && arr1.includes(arr2[index])
      );
}

// преобразование объекта в массив option для select
export function option4select(obj, order = true) {
  return isObject(obj)
    ? Object.entries(obj).map(([value, text]) =>
        order ? { value, text } : { value: text, text: value }
      )
    : [];
}
// глобальное обновление для всех запросов
// обновление "колокольчика" только один раз после всех запросов
let timer;
export function globalUpdate(timeout = 2000) {
  if (ff.NOTIFICATION) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      store.dispatch("homeStats/updateNewNotifyLk", null, { root: true });
    }, timeout);
  }
}

// добавление ссылок для передачи путей в бэк
export function addLinks(obj) {
  obj.front_paths = { ...links };
  return obj;
}
export function extractKeysWithRequiredValue(obj) {
  let keys = [];
  for (const key in obj) {
    const value = obj[key];
    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      // eslint-disable-next-line no-prototype-builtins
      if (value.hasOwnProperty("required") && value.required === true) {
        keys.push(key);
      } else {
        keys.push(...extractKeysWithRequiredValue(value));
      }
    }
  }
  return keys;
}
