import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_row = _resolveComponent("b-form-row");
  const _component_b_form_input = _resolveComponent("b-form-input");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "6"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "28. Адрес места регистрации(с индексом)"
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "5"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_select, {
        modelValue: _ctx.registry.registration_type,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.registry.registration_type = $event),
        options: $options.registrationTypeOption,
        onChange: _cache[1] || (_cache[1] = $event => $options.submitDataEndpoint(_ctx.registry, 'registration_type')),
        state: _ctx.registry.registration_type_state,
        disabled: $options.readonly,
        class: "mb-3"
      }, null, 8, ["modelValue", "options", "state", "disabled"])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "",
      lg: "3",
      xl: "2"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Индекс"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.registry.index,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.registry.index = $event),
          state: _ctx.registry.index_state,
          onChange: _cache[3] || (_cache[3] = $event => $options.submitDataEndpoint(_ctx.registry, 'index')),
          disabled: $options.readonly,
          trim: ""
        }, null, 8, ["modelValue", "state", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "",
      lg: "9",
      xl: "10"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Адрес"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.registry.address,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.registry.address = $event),
          state: _ctx.registry.address_state,
          onChange: _cache[5] || (_cache[5] = $event => $options.submitDataEndpoint(_ctx.registry, 'address')),
          disabled: $options.readonly,
          trim: ""
        }, null, 8, ["modelValue", "state", "disabled"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]);
}