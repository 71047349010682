// разметка для формы КИ
const coiTemplate = {
  foundations: {
    title:
      "Основания осуществления деятельности на основании гражданско-правового договора",
  },
  entity_name: {
    title: "Наименование ЮЛ или ИП",
  },
  entity_inn: { title: "ИНН ЮЛ или ИП" },
  position: {
    title: "Занимаемая должность",
  },
  ip_inn: { title: "ИНН ИП" },
  ogrnip: { title: "ОГРНИП" },
  has_influence: {
    title: "Наличие возможности оказывать влияние на решения ЮЛ",
  },
  relation_degree: {
    title: "Связь с подателем декларации",
  },
  first_name: { title: "Имя" },
  middle_name: { title: "Отчество" },
  last_name: { title: "Фамилия" },
  civil_service_kind: {
    title: "Вид службы/деятельности",
  },
  service_place: { title: "Место работы/службы" },
  service_start: {
    title: "Дата начала службы/работы",
    type: "data",
  },
  service_end: {
    title: "Дата окончания службы/работы",
    type: "data",
  },
  description: { title: "Иные конфликты и нарушения" },
};

export default coiTemplate;
