import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "ms-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox");
  const _component_b_form_textarea = _resolveComponent("b-form-textarea");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_b_form_row = _resolveComponent("b-form-row");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_form_group, {
    label: "Наименование организации"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_input, {
      modelValue: _ctx.partnerForm.full_name,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.partnerForm.full_name = $event),
      onChange: _cache[1] || (_cache[1] = $event => $options.changeData('full_name')),
      placeholder: "Введите наименование организации",
      trim: "",
      disabled: $options.readOnly,
      state: _ctx.state.full_name
    }, null, 8, ["modelValue", "disabled", "state"])]),
    _: 1
  }), _createVNode(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "ИНН"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          type: "number",
          modelValue: _ctx.partnerForm.inn,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.partnerForm.inn = $event),
          onChange: _cache[3] || (_cache[3] = $event => $options.changeData('inn')),
          placeholder: "Введите ИНН",
          trim: "",
          disabled: $options.readOnly
        }, null, 8, ["modelValue", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Год создания организации"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.partnerForm.creation_year,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.partnerForm.creation_year = $event),
          onChange: _cache[5] || (_cache[5] = $event => $options.changeData('creation_year')),
          type: "number",
          trim: "",
          disabled: $options.readOnly
        }, null, 8, ["modelValue", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "ОКВЭД"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          type: "number",
          modelValue: _ctx.partnerForm.main_okved,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.partnerForm.main_okved = $event),
          onChange: _cache[7] || (_cache[7] = $event => $options.changeData('main_okved')),
          placeholder: "Введите ОКВЭД",
          trim: "",
          disabled: $options.readOnly
        }, null, 8, ["modelValue", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Количество руководителей"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.partnerForm.managers_count,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _ctx.partnerForm.managers_count = $event),
          onChange: _cache[9] || (_cache[9] = $event => $options.changeData('managers_count')),
          placeholder: "Введите количество",
          type: "number",
          trim: "",
          disabled: $options.readOnly
        }, null, 8, ["modelValue", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Количество работников"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.partnerForm.workers_count,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _ctx.partnerForm.workers_count = $event),
          onChange: _cache[11] || (_cache[11] = $event => $options.changeData('workers_count')),
          placeholder: "Введите количество",
          type: "number",
          trim: "",
          disabled: $options.readOnly
        }, null, 8, ["modelValue", "disabled"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Генеральный директор"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.partnerForm.ceo,
          "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => _ctx.partnerForm.ceo = $event),
          onChange: _cache[13] || (_cache[13] = $event => $options.changeData('ceo')),
          placeholder: "Введите ФИО",
          trim: "",
          disabled: $options.readOnly,
          state: _ctx.state.ceo
        }, null, 8, ["modelValue", "disabled", "state"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Главный бухгалтер"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.partnerForm.chief_accountant,
          "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => _ctx.partnerForm.chief_accountant = $event),
          onChange: _cache[15] || (_cache[15] = $event => $options.changeData('chief_accountant')),
          placeholder: "Введите ФИО",
          trim: "",
          disabled: $options.readOnly,
          state: _ctx.state.chief_accountant
        }, null, 8, ["modelValue", "disabled", "state"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Телефон организации"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.partnerForm.phone,
          "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => _ctx.partnerForm.phone = $event),
          onChange: _cache[17] || (_cache[17] = $event => $options.changeData('phone')),
          placeholder: "Введите номер",
          disabled: $options.readOnly
        }, null, 8, ["modelValue", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Электронная почта"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.partnerForm.email,
          "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => _ctx.partnerForm.email = $event),
          onChange: _cache[19] || (_cache[19] = $event => $options.changeData('email')),
          placeholder: "Введите почту",
          type: "email",
          trim: "",
          disabled: $options.readOnly,
          state: _ctx.state.email
        }, null, 8, ["modelValue", "disabled", "state"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Веб-сайт"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.partnerForm.website,
          "onUpdate:modelValue": _cache[20] || (_cache[20] = $event => _ctx.partnerForm.website = $event),
          onChange: _cache[21] || (_cache[21] = $event => $options.changeData('website')),
          placeholder: "Введите сайт",
          trim: "",
          disabled: $options.readOnly
        }, null, 8, ["modelValue", "disabled"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    label: "Юридический адрес"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_input, {
      modelValue: _ctx.partnerForm.legal_address,
      "onUpdate:modelValue": _cache[22] || (_cache[22] = $event => _ctx.partnerForm.legal_address = $event),
      onChange: _cache[23] || (_cache[23] = $event => $options.changeData('legal_address')),
      placeholder: "Введите адрес",
      trim: "",
      disabled: $options.readOnly
    }, null, 8, ["modelValue", "disabled"])]),
    _: 1
  }), _createVNode(_component_b_row, {
    class: "mb-1"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "2"
    }, {
      default: _withCtx(() => [_createTextVNode("Фактический адрес")]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_checkbox, {
        modelValue: $options.addressEqual,
        "onUpdate:modelValue": _cache[24] || (_cache[24] = $event => $options.addressEqual = $event),
        disabled: $options.readOnly
      }, {
        default: _withCtx(() => [_createTextVNode(" Совпадает с юридическим адресом ")]),
        _: 1
      }, 8, ["modelValue", "disabled"])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_form_input, {
    modelValue: _ctx.partnerForm.actual_address,
    "onUpdate:modelValue": _cache[25] || (_cache[25] = $event => _ctx.partnerForm.actual_address = $event),
    onChange: _cache[26] || (_cache[26] = $event => $options.changeData('actual_address')),
    placeholder: "Введите адрес",
    trim: "",
    disabled: $options.readOnly,
    class: "mb-2"
  }, null, 8, ["modelValue", "disabled"]), _createVNode(_component_b_form_group, {
    label: "Банковские реквизиты"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_textarea, {
      modelValue: _ctx.partnerForm.banking_details,
      "onUpdate:modelValue": _cache[27] || (_cache[27] = $event => _ctx.partnerForm.banking_details = $event),
      placeholder: "Введите реквизиты",
      rows: "3",
      "max-rows": "3",
      trim: "",
      onChange: _cache[28] || (_cache[28] = $event => $options.changeData('banking_details')),
      disabled: $options.readOnly
    }, null, 8, ["modelValue", "disabled"])]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    label: "Учредители, собственники, бенефициарный владелец (доли в уставном капитале)"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_textarea, {
      modelValue: _ctx.partnerForm.company_founders_text,
      "onUpdate:modelValue": _cache[29] || (_cache[29] = $event => _ctx.partnerForm.company_founders_text = $event),
      placeholder: "Введите данные",
      rows: "3",
      "max-rows": "3",
      trim: "",
      onChange: _cache[30] || (_cache[30] = $event => $options.changeData('company_founders_text')),
      disabled: $options.readOnly
    }, null, 8, ["modelValue", "disabled"])]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    label: "Члены коллегиального исполнительного органа"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_textarea, {
      modelValue: _ctx.partnerForm.management_board_members,
      "onUpdate:modelValue": _cache[31] || (_cache[31] = $event => _ctx.partnerForm.management_board_members = $event),
      placeholder: "Введите данные",
      rows: "3",
      "max-rows": "3",
      trim: "",
      onChange: _cache[32] || (_cache[32] = $event => $options.changeData('management_board_members')),
      disabled: $options.readOnly
    }, null, 8, ["modelValue", "disabled"])]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    label: "Имеется ли в структуре собственников организации государственное должностное лицо"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_textarea, {
      modelValue: _ctx.partnerForm.civil_servant,
      "onUpdate:modelValue": _cache[33] || (_cache[33] = $event => _ctx.partnerForm.civil_servant = $event),
      placeholder: "Введите данные",
      rows: "3",
      "max-rows": "3",
      trim: "",
      onChange: _cache[34] || (_cache[34] = $event => $options.changeData('civil_servant')),
      disabled: $options.readOnly
    }, null, 8, ["modelValue", "disabled"])]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    label: `Работник, ответственный за взаимодействие с ${$options.orgName}`
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_input, {
      modelValue: _ctx.partnerForm.communicator,
      "onUpdate:modelValue": _cache[35] || (_cache[35] = $event => _ctx.partnerForm.communicator = $event),
      onChange: _cache[36] || (_cache[36] = $event => $options.changeData('communicator')),
      placeholder: "Введите ФИО",
      trim: "",
      disabled: $options.readOnly,
      state: _ctx.state.communicator
    }, null, 8, ["modelValue", "disabled", "state"])]),
    _: 1
  }, 8, ["label"]), _createVNode(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12",
      md: "4",
      lg: "4",
      xl: "4"
    }, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: "Дата заполнения анкеты",
        date: _ctx.partnerForm.profileDate,
        classCustom: "mt-2",
        onChangeValue: _cache[37] || (_cache[37] = $event => $options.changeData('profile_offline_date', $event)),
        disabled: $options.readOnly
      }, null, 8, ["date", "disabled"])]),
      _: 1
    })]),
    _: 1
  }), $options.isAdminPath ? (_openBlock(), _createBlock(_component_b_form_row, {
    key: 0,
    class: "mt-4"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createElementVNode("span", null, " Договор № " + _toDisplayString(_ctx.partnerForm.contract_num || "-") + " от " + _toDisplayString($options.formatDate(_ctx.partnerForm.contract_date, _ctx.fmtDateShort)), 1), _createElementVNode("span", _hoisted_1, _toDisplayString($options.allContracts), 1)]),
      _: 1
    })]),
    _: 1
  })) : _createCommentVNode("", true)]);
}