import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_modal = _resolveComponent("b-modal");
  return _openBlock(), _createElementBlock("div", null, [$props.settings.buttonIcon ? (_openBlock(), _createBlock(_component_b_button, {
    key: 0,
    variant: "link",
    onClick: $options.changeOrg,
    class: "border-0"
  }, {
    default: _withCtx(() => [_createElementVNode("i", {
      class: _normalizeClass(`bi bi-${$props.settings.iconType}`)
    }, null, 2)]),
    _: 1
  }, 8, ["onClick"])) : (_openBlock(), _createBlock(_component_b_button, {
    key: 1,
    variant: "primary",
    onClick: $options.changeOrg,
    "data-hook": "buttonAddOrg"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($props.settings.btnTitle), 1)]),
    _: 1
  }, 8, ["onClick"])), _createVNode(_component_b_modal, {
    modelValue: _ctx.showModal,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.showModal = $event),
    id: $props.idModal,
    title: $props.settings.title,
    centered: "",
    onOk: $options.handlerOk,
    "ok-title": "Сохранить",
    "cancel-title": "Отменить",
    "cancel-variant": "outline-primary",
    scrollable: "",
    "no-close-on-backdrop": ""
  }, {
    default: _withCtx(() => [_createElementVNode("form", {
      ref: "form-login",
      onSubmit: _cache[3] || (_cache[3] = _withModifiers((...args) => $options.handlerOk && $options.handlerOk(...args), ["stop", "prevent"]))
    }, [_createVNode(_component_b_form_group, {
      label: "Название",
      "label-for": "modal-name"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_input, {
        id: "modal-name",
        modelValue: _ctx.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.name = $event),
        required: "",
        type: "text",
        placeholder: "Укажите название",
        state: _ctx.nameState,
        onInput: $options.validateName,
        trim: ""
      }, null, 8, ["modelValue", "state", "onInput"])]),
      _: 1
    }), _createVNode(_component_b_form_group, {
      label: "ИНН",
      "label-for": "modal-inn"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_input, {
        id: "modal-inn",
        modelValue: _ctx.inn,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.inn = $event),
        required: "",
        type: "text",
        placeholder: "Укажите ИНН",
        state: _ctx.innState,
        onInput: $options.validateINN,
        trim: ""
      }, null, 8, ["modelValue", "state", "onInput"])]),
      _: 1
    }), _createVNode(_component_b_form_group, {
      label: "Краткое название"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_input, {
        modelValue: _ctx.shortname,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.shortname = $event),
        type: "text",
        placeholder: "Укажите название",
        trim: ""
      }, null, 8, ["modelValue"])]),
      _: 1
    })], 544)]),
    _: 1
  }, 8, ["modelValue", "id", "title", "onOk"])]);
}