import "core-js/modules/es.array.push.js";
import Calendar from "@/components/Helpers/Calendar.vue";
import { mapActions, mapGetters } from "vuex";
import { formatDateObj, fmtDate, formatDate, fmtDateShort } from "@/helpers/date";
import { statesPartners } from "@/helpers/states";
import { validate } from "@/helpers/validate";
import { getOrgName } from "@/auth/auth";
import links from "@/router/links";
export default {
  name: "PartnersFormAbout",
  components: {
    Calendar
  },
  data: () => ({
    state: {
      email: null,
      full_name: null,
      ceo: null,
      chief_accountant: null,
      communicator: null
    },
    arrValidate: ["email", "full_name", "ceo", "chief_accountant", "communicator"],
    dataRequired: {
      email: "email",
      full_name: "rusText",
      ceo: "rusText",
      chief_accountant: "rusText",
      communicator: "rusText"
    },
    fmtDateShort
  }),
  methods: {
    ...mapActions("partners", ["changePartner"]),
    formatDate,
    async changeData(key, date) {
      let value = this.partnerForm[key];
      if (key === "profile_offline_date") {
        value = formatDateObj(date, fmtDate, null);
        if (!value) return;
      } else if (this.arrValidate.includes(key)) {
        const valid = validate({
          data: value,
          dataRequired: this.dataRequired[key]
        });
        this.state[key] = valid ? null : valid;
        if (!valid) return;
      }
      await this.changePartner({
        id: this.partnerForm.id,
        params: {
          [key]: value
        }
      });
    }
  },
  computed: {
    ...mapGetters("partners", ["partnerForm"]),
    readOnly() {
      return statesPartners[this.partnerForm.state] !== statesPartners.draft;
    },
    orgName() {
      return getOrgName();
    },
    addressEqual: {
      get() {
        return this.partnerForm.legal_address === this.partnerForm.actual_address;
      },
      async set(value) {
        if (value) {
          this.partnerForm.actual_address = this.partnerForm.legal_address;
          await this.changeData("actual_address");
        }
      }
    },
    isAdminPath() {
      return this.$route.path.includes(links.adminPartnersForm);
    },
    allContracts() {
      if (this.partnerForm.all_contracts_data.length > 0) return `(${this.partnerForm.all_contracts_data.reduce((acc, curr) => {
        const key = Object.keys(curr)[0];
        if (key !== this.partnerForm.contract_num) acc.push("№ " + key + " от " + formatDate(curr[key], fmtDateShort));
        return acc;
      }, []).join(", ")})`;else return "";
    }
  }
};