import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_row = _resolveComponent("b-form-row");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "29. Фактический адрес проживания (с индексом):"
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "6"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Индекс"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.home.index,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.home.index = $event),
          state: _ctx.home.index_state,
          onChange: _cache[1] || (_cache[1] = $event => $options.submitDataEndpoint(_ctx.home, 'index')),
          disabled: $options.readonly,
          trim: ""
        }, null, 8, ["modelValue", "state", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "6"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Адрес"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.home.address,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.home.address = $event),
          state: _ctx.home.address_state,
          onChange: _cache[3] || (_cache[3] = $event => $options.submitDataEndpoint(_ctx.home, 'address')),
          trim: "",
          disabled: $options.readonly
        }, null, 8, ["modelValue", "state", "disabled"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]);
}